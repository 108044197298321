<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="650"
      @click:outside="closePaymentUpdate()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          icon
          v-on="on">
          <v-icon
            color="primary"
            class="ml-2">
            mdi-square-edit-outline
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closePaymentUpdate()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <title-label :label="$t('payment.label.editBank')" />
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-3"
            lazy-validation
            @submit.prevent="submit()">
            <payment-form :form="form" />
          </v-form>
          <div class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              text
              @click="closePaymentUpdate()">
              {{ $t('payment.btn.cancel') }}
            </v-btn>
            <save-change-button
              class="mr-1"
              :state="state"
              :disabled="!valid"
              :label="$t('payment.btn.save')"
              @click="submit()">
            </save-change-button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentForm from '@/views/ShopSetting/Pages/Payment/components/PaymentGateway/PaymentForm'
import { GET_BANK_ACCOUNT } from '@/resources/graphql/query/BankAccount'
import { UPDATE_BANK_ACCOUNT } from '@/resources/graphql'

export default {
  name: 'PaymentUpdate',
    apollo: {
    bankAccount: () => ({
      fetchPolicy: 'no-cache',
      query: GET_BANK_ACCOUNT,
      variables () {
        return {
          id: this.bankId
        }
      },
      result ({ data: { bankAccount } }) {
        this.form.bank = bankAccount.bank
        this.form.accountBranch = bankAccount.accountBranch
        this.form.accountName = bankAccount.accountName
        this.form.account = bankAccount.account
        if (bankAccount.stock) {
          this.form.stockId = bankAccount.stock.id
        } else {
          this.form.stockId = null
        }
      }
    })
  },
  components: {
    PaymentForm
  },
  props: {
    bankId: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      form: {
        bank: '',
        accountBranch: '',
        accountName: '',
        account: '',
        stockId: ''
      },
      valid: true,
      state: 'ready'
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    closePaymentUpdate () {
      this.dialog = false
      this.$refs.form.resetValidation()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_BANK_ACCOUNT,
          variables: {
            input: {
              id: this.bankId,
              bank: this.form.bank,
              accountBranch: this.form.accountBranch,
              accountName: this.form.accountName,
              account: this.form.account,
              stockId: this.form.stockId
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          const { data: { updateBankAccount } } = res
          if (updateBankAccount.errors && Object.keys(updateBankAccount.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
          }
          this.$emit('submit')
          this.$refs.form.resetValidation()
          setTimeout(() => {
            this.state = 'ready'
          }, 3000)
        }
      }
    }
  }
}
</script>
