<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="bankAccounts.nodes"
      class="app-table"
      hide-default-footer>
      <template v-slot:[`header.bank`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.accountName`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.accountBranch`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.account`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.stock`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`item.bank`]="{ item }">
        {{ bankDisplay(item) }}
      </template>
      <template v-slot:[`item.stock`]="{ item }">
        {{ stockDisplay(item) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex">
          <payment-update
            :bank-id="item.id"
            @submit="refetch()">
          </payment-update>
          <payment-remove
            :bank-id="item.id"
            @submit="refetch()">
          </payment-remove>
        </div>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="bankAccounts.pagesCount"
      :total-visible="7"
      class="mt-3">
    </v-pagination>
  </div>
</template>

<script>
import PaymentUpdate from '@/views/ShopSetting/Pages/Payment/components/PaymentGateway/PaymentUpdate.vue'
import PaymentRemove from '@/views/ShopSetting/Pages/Payment/components/PaymentGateway/PaymentRemove.vue'
import { GET_BANK_ACCOUNTS } from '@/resources/graphql/query/BankAccounts'
import { GET_SETTING_AVAILABLEBANKS } from '@/resources/graphql/query/Setting'

export default {
  name: 'PaymentGateway',
  apollo: {
    bankAccounts: () => ({
      fetchPolicy: 'no-cache',
      query: GET_BANK_ACCOUNTS,
      variables () {
        return {
          page: this.page,
          perPage: this.perPage
        }
      },
      result ({ data: { bankAccounts } }) {
        if (this.page > bankAccounts.pagesCount) {
          this.page -= 1
        }
      }
    }),
    setting: () => ({
      query: GET_SETTING_AVAILABLEBANKS
    })
  },
  components: {
    PaymentUpdate,
    PaymentRemove
  },
  data () {
    return {
      bankAccounts: [],
      setting: {
        availableBanks: []
      },
      page: 1,
      perPage: 5,
      headers: [
        {
          text: this.$t('payment.table.header.bank'),
          align: 'start',
          value: 'bank',
          sortable: false
        },
        {
          text: this.$t('payment.table.header.bankAccount'),
          align: 'start',
          value: 'accountName',
          sortable: false
        },
        {
          text: this.$t('payment.table.header.branch'),
          align: 'start',
          value: 'accountBranch',
          sortable: false
        },
        {
          text: this.$t('payment.table.header.bankNumber'),
          align: 'start',
          value: 'account',
          sortable: false
        },
        {
          text: this.$t('payment.table.header.warehouse'),
          align: 'start',
          value: 'stock',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  methods: {
    bankDisplay (item) {
      const bank = this.setting.availableBanks.find((x) => x.value === item.bank)
      if (bank) {
        return bank.display_name
      }
      return null
    },
    stockDisplay (item) {
      if (item.stock) {
        return item.stock.name
      }
      return 'ทุกคลัง'
    },
    refetch () {
      this.$apollo.queries.bankAccounts.refetch()
    }
  }
}
</script>
