<template>
  <div>
    <v-card flat>
      <v-card-text>
        <title-label
          :label="$t('payment.label.acceptCreditCard')"
          prepend-icon="$creditCardTitle" />
        <v-row
          class="mb-4"
          no-gutters>
          <v-col
            cols="5"
            class="pt-1 pl-6">
            <span class="ml-12">
              {{ $t('payment.creditCard.acceptCreditCard') }}
            </span>
          </v-col>
          <v-col>
            <v-switch
              v-model="setting.acceptCreditCard"
              class="mt-0"
              inset
              hide-details>
            </v-switch>
          </v-col>
        </v-row>
        <v-row
          class="mb-4"
          no-gutters>
          <v-col
            cols="5"
            class="pt-2 pl-12">
            <span
              :class="{ 'disabled--text': !setting.acceptCreditCard }"
              class="pl-12 ml-12">
              {{ $t('payment.creditCard.customerCreditCardRate') }}
            </span>
          </v-col>
          <v-col>
            <v-text-field
              v-model="setting.customerCreditCardRate"
              :disabled="!setting.acceptCreditCard"
              style="max-width: 60%;"
              dense
              outlined
              hide-details />
            <span class="caption">
              {{ $t('payment.creditCard.hint') }}
            </span>
          </v-col>
        </v-row>
        <v-row
          class="mb-4"
          no-gutters>
          <v-col
            cols="5"
            class="pl-12">
            <title-label
              :label="$t('payment.label.creditCardTransferBankAccount')"
              prepend-icon="$creditCardTitle" />
          </v-col>
        </v-row>
        <v-row
          class="mb-4"
          no-gutters>
          <v-col
            cols="5"
            class="pt-2 pl-12">
            <span class="pl-12 ml-12">
              {{ $t('payment.creditCard.creditCardTransferBankAccountName') }}
            </span>
          </v-col>
          <v-col>
            <v-select
              v-model="setting.creditCardTransferBankAccountName"
              :items="setting.paymentMethods"
              item-text="display_name"
              item-value="value"
              style="max-width: 200px;"
              attach
              dense
              outlined
              hide-details>
            </v-select>
          </v-col>
        </v-row>
        <v-row
          class="mb-4"
          no-gutters>
          <v-col
            cols="5"
            class="pt-2 pl-12">
            <span class="pl-12 ml-12">
              {{ $t('payment.creditCard.creditCardTransferBankName') }}
            </span>
          </v-col>
          <v-col>
            <v-text-field
              v-model="setting.creditCardTransferBankName"
              style="max-width: 60%;"
              dense
              outlined
              hide-details />
          </v-col>
        </v-row>
        <v-row
          class="mb-4"
          no-gutters>
          <v-col
            cols="5"
            class="pt-2 pl-12">
            <span class="pl-12 ml-12">
              {{ $t('payment.creditCard.creditCardTransferBankAccount') }}
            </span>
          </v-col>
          <v-col>
            <v-text-field
              v-model="setting.creditCardTransferBankAccount"
              style="max-width: 60%;"
              dense
              outlined
              hide-details />
          </v-col>
        </v-row>
        <v-row
          class="mb-4"
          no-gutters>
          <v-col
            cols="5"
            class="pt-2 pl-12">
            <span class="pl-12 ml-12">
              {{ $t('payment.creditCard.creditCardTransferBankAccountBranch') }}
            </span>
          </v-col>
          <v-col>
            <v-text-field
              v-model="setting.creditCardTransferBankAccountBranch"
              style="max-width: 60%;"
              dense
              outlined
              hide-details />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-spacer />
          <v-col cols="7">
            <save-change-button
              :state="state"
              :label="$t('payment.btn.save')"
              @click="submit()">
            </save-change-button>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div
      style="background: #F1F2F5;"
      class="pt-4">
      <label>
        {{ $t('payment.label.incomeTransferAgreement') }}
      </label>
      <pre
        class="caption mt-2 ml-0">
        {{ $t('payment.creditCard.agreementLine1') }}
        {{ $t('payment.creditCard.agreementLine2') }}
        {{ $t('payment.creditCard.agreementLine3') }}
        {{ $t('payment.creditCard.agreementLine4') }}
        {{ $t('payment.creditCard.agreementLine5') }}
        {{ $t('payment.creditCard.agreementLine6') }}
        {{ $t('payment.creditCard.agreementLine7') }}
      </pre>
    </div>
  </div>
</template>

<script>
import { GET_SETTING_PAYMENT } from '@/resources/graphql/query/Setting'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'CreditCard',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_PAYMENT,
      result ({ data: { setting } }) {
        if (!setting.creditCardTransferBankName) {
          this.setting.paymentMethods.unshift({
            display_name: 'เลือกธนาคาร',
            value: ''
          })
        }
      }
    })
  },
  data () {
    return {
      setting: {},
      state: 'ready'
    }
  },
  methods: {
    formatNumber (name) {
      this.setting[name] = Number(this.setting[name])
      this.submit(name)
    },
    async submit () {
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            acceptCreditCard: this.setting.acceptCreditCard,
            customerCreditCardRate: Number(this.setting.customerCreditCardRate),
            creditCardTransferBankName: this.setting.creditCardTransferBankName,
            creditCardTransferBankAccountName: this.setting.creditCardTransferBankAccountName,
            creditCardTransferBankAccount: this.setting.creditCardTransferBankAccount,
            creditCardTransferBankAccountBranch: this.setting.creditCardTransferBankAccountBranch
          }
        }
      }).catch(() => {})
      if (res) {
        this.state = 'success'
      }
      setTimeout(() => {
        this.state = 'ready'
      }, 2000)
    }
  }
}
</script>

<style scoped>
</style>
