var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "app-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.bankAccounts.nodes,
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header.bank",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.accountName",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.accountBranch",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.account",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.stock",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "item.bank",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.bankDisplay(item)) + " ")]
              }
            },
            {
              key: "item.stock",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.stockDisplay(item)) + " ")]
              }
            },
            {
              key: "item.action",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("payment-update", {
                        attrs: { "bank-id": item.id },
                        on: {
                          submit: function($event) {
                            return _vm.refetch()
                          }
                        }
                      }),
                      _c("payment-remove", {
                        attrs: { "bank-id": item.id },
                        on: {
                          submit: function($event) {
                            return _vm.refetch()
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("v-pagination", {
        staticClass: "mt-3",
        attrs: { length: _vm.bankAccounts.pagesCount, "total-visible": 7 },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }