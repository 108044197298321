<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="650"
      @click:outside="closePaymentCreate()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="mr-2"
          rounded
          depressed
          v-bind="attrs"
          v-on="on">
          <v-icon>
            mdi-plus-circle
          </v-icon>
          <span>
            {{ $t('payment.btn.bank') }}
          </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closePaymentCreate()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <title-label :label="$t('payment.label.addBank')" />
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-3"
            lazy-validation
            @submit.prevent="submit()">
            <payment-form :form="form" />
          </v-form>
          <div class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              text
              @click="closePaymentCreate()">
              {{ $t('payment.btn.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              rounded
              depressed
              @click="submit()">
              <span>
                {{ $t('payment.btn.confirm') }}
              </span>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentForm from '@/views/ShopSetting/Pages/Payment/components/PaymentGateway/PaymentForm'
import { CREATE_BANK_ACCOUNT } from '@/resources/graphql'

export default {
  name: 'PaymentCreate',
  components: {
    PaymentForm
  },
  data () {
    return {
      dialog: false,
      form: {
        bank: 'kbank',
        accountBranch: '',
        accountName: '',
        account: '',
        stockId: null
      },
      valid: true,
      state: 'ready'
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    resetForm () {
      this.form = {
        bank: 'kbank',
        accountBranch: '',
        accountName: '',
        account: '',
        stockId: null
      }
    },
    closePaymentCreate () {
      this.dialog = false
      this.resetForm()
      this.$refs.form.resetValidation()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: CREATE_BANK_ACCOUNT,
          variables: {
            input: {
              bank: this.form.bank,
              accountBranch: this.form.accountBranch,
              accountName: this.form.accountName,
              account: this.form.account,
              stockId: this.form.stockId
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          const { data: { createBankAccount } } = res
          if (createBankAccount.errors && Object.keys(createBankAccount.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
          }
          this.$emit('submit')
          this.resetForm()
          this.$refs.form.resetValidation()
          this.dialog = false
        }
      }
    }
  }
}
</script>
