<template>
  <div>
    <v-row
      class="mt-3"
      no-gutters>
      <v-col class="mr-5">
        <text-field-label
          :label="$t('payment.textField.bank.label')">
        </text-field-label>
        <v-select
          v-model="form.bank"
          :placeholder="$t('payment.textField.bank.placeholder')"
          :items="setting.availableBanks"
          item-text="display_name"
          item-value="value"
          dense
          outlined>
        </v-select>
      </v-col>
      <v-col>
        <text-field-label
          :label="$t('payment.textField.branch.label')">
        </text-field-label>
        <v-text-field
          v-model="form.accountBranch"
          :placeholder="$t('payment.textField.branch.placeholder')"
          :rules="branchRules"
          dense
          outlined
          required />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <text-field-label
          :label="$t('payment.textField.bankAccount.label')">
        </text-field-label>
        <v-text-field
          v-model="form.accountName"
          :placeholder="$t('payment.textField.bankAccount.placeholder')"
          :rules="bankAccountNameRules"
          dense
          outlined
          required />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="mr-5">
        <text-field-label
          :label="$t('payment.textField.bankNumber.label')">
        </text-field-label>
        <v-text-field
          v-model="form.account"
          :placeholder="$t('payment.textField.bankNumber.placeholder')"
          :rules="bankAccountNumberRules"
          dense
          outlined
          required />
      </v-col>
      <v-col>
        <text-field-label
          :label="$t('payment.textField.warehouse.label')">
        </text-field-label>
        <v-select
          v-model="form.stockId"
          :placeholder="$t('payment.textField.warehouse.placeholder')"
          :items="stocks.nodes"
          item-text="name"
          item-value="id"
          dense
          outlined>
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { GET_SETTING_AVAILABLEBANKS } from '@/resources/graphql/query/Setting'
import { GET_STOCKS } from '@/resources/graphql/query/Stocks'

export default {
  name: 'PaymentForm',
  apollo: {
    stocks: () => ({
      query: GET_STOCKS,
      variables () {
        return {
          page: this.page,
          perPage: this.perPage
        }
      },
      result () {
        this.stocks.nodes.unshift({
          id: null,
          name: 'ทั้งหมด'
        })
      }
    }),
    setting: () => ({
      query: GET_SETTING_AVAILABLEBANKS
    })
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      page: 1,
      perPage: 1000,
      stocks: [],
      setting: {},
      branchRules: [
        (v) => !!v || this.$t('payment.textField.branch.validate.required')
      ],
      bankAccountNameRules: [
        (v) => !!v || this.$t('payment.textField.bankAccount.validate.required')
      ],
      bankAccountNumberRules: [
        (v) => !!v || this.$t('payment.textField.bankNumber.validate.required')
      ]
    }
  }
}
</script>

<style>

</style>
