import { render, staticRenderFns } from "./PaymentForm.vue?vue&type=template&id=80748042&"
import script from "./PaymentForm.vue?vue&type=script&lang=js&"
export * from "./PaymentForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VRow,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('80748042')) {
      api.createRecord('80748042', component.options)
    } else {
      api.reload('80748042', component.options)
    }
    module.hot.accept("./PaymentForm.vue?vue&type=template&id=80748042&", function () {
      api.rerender('80748042', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ShopSetting/Pages/Payment/components/PaymentGateway/PaymentForm.vue"
export default component.exports