var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "mr-5" },
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("payment.textField.bank.label") }
              }),
              _c("v-select", {
                attrs: {
                  placeholder: _vm.$t("payment.textField.bank.placeholder"),
                  items: _vm.setting.availableBanks,
                  "item-text": "display_name",
                  "item-value": "value",
                  dense: "",
                  outlined: ""
                },
                model: {
                  value: _vm.form.bank,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bank", $$v)
                  },
                  expression: "form.bank"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("payment.textField.branch.label") }
              }),
              _c("v-text-field", {
                attrs: {
                  placeholder: _vm.$t("payment.textField.branch.placeholder"),
                  rules: _vm.branchRules,
                  dense: "",
                  outlined: "",
                  required: ""
                },
                model: {
                  value: _vm.form.accountBranch,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "accountBranch", $$v)
                  },
                  expression: "form.accountBranch"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("payment.textField.bankAccount.label") }
              }),
              _c("v-text-field", {
                attrs: {
                  placeholder: _vm.$t(
                    "payment.textField.bankAccount.placeholder"
                  ),
                  rules: _vm.bankAccountNameRules,
                  dense: "",
                  outlined: "",
                  required: ""
                },
                model: {
                  value: _vm.form.accountName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "accountName", $$v)
                  },
                  expression: "form.accountName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "mr-5" },
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("payment.textField.bankNumber.label") }
              }),
              _c("v-text-field", {
                attrs: {
                  placeholder: _vm.$t(
                    "payment.textField.bankNumber.placeholder"
                  ),
                  rules: _vm.bankAccountNumberRules,
                  dense: "",
                  outlined: "",
                  required: ""
                },
                model: {
                  value: _vm.form.account,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "account", $$v)
                  },
                  expression: "form.account"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("text-field-label", {
                attrs: { label: _vm.$t("payment.textField.warehouse.label") }
              }),
              _c("v-select", {
                attrs: {
                  placeholder: _vm.$t(
                    "payment.textField.warehouse.placeholder"
                  ),
                  items: _vm.stocks.nodes,
                  "item-text": "name",
                  "item-value": "id",
                  dense: "",
                  outlined: ""
                },
                model: {
                  value: _vm.form.stockId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "stockId", $$v)
                  },
                  expression: "form.stockId"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }