import gql from 'graphql-tag'

export const GET_BANK_ACCOUNT = gql`
  query ($id: ID!) {
    bankAccount(id: $id) {
      id
      account
      accountBranch
      accountName
      bank
      stock {
        id
      }
    }
  }
`

export default {
  GET_BANK_ACCOUNT
}
