<template>
  <div>
    <div class="d-flex align-center">
      <header-label
        :label="$t('payment.header')"
        prepend-icon="$paymentHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-tabs
      v-model="tab"
      class="app-tab"
      show-arrows>
      <div
        v-if="tab === 0"
        class="btn-add-bank">
        <payment-create @submit="refetchBankAccount()" />
      </div>
      <v-tab v-if="permission.settingBankAccount">
        {{ $t('payment.tab.paymentGateway') }}
      </v-tab>
      <v-tab v-if="permission.settingCreditCard">
        {{ $t('payment.tab.creditCard') }}
      </v-tab>
      <v-tab-item v-if="permission.settingBankAccount">
        <v-card flat>
          <v-card-text>
            <payment-data-table ref="PaymentDataTable" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="permission.settingCreditCard">
        <credit-card />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PaymentDataTable from '@/views/ShopSetting/Pages/Payment/components/PaymentGateway/PaymentDataTable'
import PaymentCreate from '@/views/ShopSetting/Pages/Payment/components/PaymentGateway/PaymentCreate'
import CreditCard from '@/views/ShopSetting/Pages/Payment/components/CreditCard/CreditCard'

export default {
  name: 'Payment',
  components: {
    PaymentDataTable,
    PaymentCreate,
    CreditCard
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    ...mapGetters({
      permission: 'Auth/permission'
    })
  },
  mounted () {
    this.tab = this.$route.query.tab ? Number(this.$route.query.tab) : 0
  },
  methods: {
    refetchBankAccount () {
      this.$refs.PaymentDataTable.refetch()
    }
  }
}
</script>

<style scoped>
  .btn-add-bank {
    position: absolute;
    right: 0;
  }
</style>
