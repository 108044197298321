var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("header-label", {
            attrs: {
              label: _vm.$t("payment.header"),
              "prepend-icon": "$paymentHeader"
            }
          }),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v(" mdi-chevron-left ")
              ])
            ],
            1
          ),
          _c("span", { staticClass: "primary--text mb-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopsetting.header")) + " ")
          ])
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          staticClass: "app-tab",
          attrs: { "show-arrows": "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _vm.tab === 0
            ? _c(
                "div",
                { staticClass: "btn-add-bank" },
                [
                  _c("payment-create", {
                    on: {
                      submit: function($event) {
                        return _vm.refetchBankAccount()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.permission.settingBankAccount
            ? _c("v-tab", [
                _vm._v(" " + _vm._s(_vm.$t("payment.tab.paymentGateway")) + " ")
              ])
            : _vm._e(),
          _vm.permission.settingCreditCard
            ? _c("v-tab", [
                _vm._v(" " + _vm._s(_vm.$t("payment.tab.creditCard")) + " ")
              ])
            : _vm._e(),
          _vm.permission.settingBankAccount
            ? _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [_c("payment-data-table", { ref: "PaymentDataTable" })],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.permission.settingCreditCard
            ? _c("v-tab-item", [_c("credit-card")], 1)
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }